<template>
  <div class="main">
    <div class="wrap">
      <div class="left_wrap">
        <div class="image-title">
          <p>Welcome to</p>
          <p class="supplier">{{ $t("supplier.label_under_logo_login_screen") }}</p>
          <!-- todo: update multi lang -->
        </div>
      </div>
      <div class="right_wrap">
        <v-form class="right_block log_frm" @submit.prevent="submitForm" ref="form" lazy-validation>
          <h1 class="title-text mb-45">
            <span class="title-text-style">{{ $t("login.title_login") }}</span>
          </h1>
          <div class="input-block mb-48">
            <span class="input-title">{{ $t("login.label_email") }}</span>
            <input id="email" class="input-elic mb-0" v-model="email" @focus="handleFocusEmail" @blur="handleBlurEmail" />
            <img
              v-if="email"
              class="clear-data-input-search"
              :src="iconClose"
              alt="close"
              @click="clearDataTitleModel"
              @mouseenter="isActiveCloseIcon = true"
              @mouseleave="isActiveCloseIcon = false"
            />
            <!-- <InputField v-model="email" :isError="!email" /> -->
            <span class="err-mess" style="display: none" id="email_error"></span>
            <img
              id="email_img"
              class="validWarning"
              src="/img/icons/warning.svg"
              style="position: absolute; right: 6px; display: none"
            />
          </div>
          <div class="input-password-block">
            <div class="input-password-block-parts mt-0 mb-0">
              <div class="input-block">
                <span class="input-title">{{ $t("login.label_password") }}</span>
                <div class="passBox">
                  <input
                    id="password"
                    v-model="password"
                    class="input-elic mb-0"
                    :type="!showPass ? 'password' : 'text'"
                    @focus="handleFocusPass"
                    @blur="handleBlurPass"
                  />
                  <eye-icon :id="'password_eye'" :changeIcon="() => (this.showPass = !this.showPass)" />
                  <span class="err-mess" style="display: none" id="password_error"></span>
                  <img
                    id="password_img"
                    class="validWarning"
                    src="/img/icons/warning.svg"
                    style="position: absolute; right: 6px; display: none"
                  />
                </div>
              </div>
              <div class="forgot-block">
                <span class="forgot-block-link">
                  {{ $t("login.label_forgot_password") }} <a class="forgot-block-link-text" @click="handleCheckLockPass">{{ $t("login.hyperlink_click_here") }}</a><span :class="checkLang"></span></span
                >
              </div>
            </div>
          </div>

          <input class="btn-submit" type="submit" :value="loginText" />
        </v-form>
      </div>
    </div>
    <error-popup :dialog="dialog" :message="message" @submit="handleClose" />

    <reset-pass-popup :dialog="showCheckTokenError" :message="errorMessage" @submit="handleCloseReset()" />
  </div>
</template>

<script>
import ErrorPopup from '@/components/dialogs/error-popup.vue';
import ResetPassPopup from '@/components/dialogs/reset-pass-token-popup.vue';
import validate from '@/utils/validate';
import { loginApi, checkBlockAccApi, handleVerifyEmail } from '@/api/auth';
import { ROUTES } from '@/router/constants';
import { validateRequired } from '@/api/others';
import EyeIcon from '@/components/commonApp/EyeIcon.vue';
import { getErrorMessge } from '@/utils/messageHandle';
import { mapGetters } from 'vuex';
import InputField from '@/components/products/input/InputField.vue';
import { getClassesUnderline } from '@/utils/multiLanguage.js';
import { infoFormPrimaryDataApi, getInforPrimaryDataApiBeforeLogin } from '@/api/supplier';
import { mapActions } from 'vuex';
export default {
  props: {
    isSuplier: {
      type: String,
      default: null,
    }
  },
  data: () => ({
    showPass: false,
    dialog: false,
    message: '',
    email: '',
    emailRules: [(v) => !!v || 'error', (v) => validate.regexMail.test(v)],
    password: '',
    passwordRules: [(v) => !!v, (v) => validate.regexPassword.test(v)],

    showCheckTokenError: false,
    isActiveCloseIcon: false
  }),
  computed: {
    ...mapGetters({
      errorMessage: 'userData/getMessage',
    }),
    iconClose() {
      if (!this.isActiveCloseIcon) {
        return require('@/assets/icons/product/close-input.svg');
      }
      return require('@/assets/icons/product/close-input-active.svg');
    },
    loginText() {
      return this.$t("login.button_login");
    },
    checkLang() {
      return getClassesUnderline(this.$i18n.locale);
    },
  },

  created() {
    if(localStorage.getItem('language')) this.$i18n.locale = localStorage.getItem('language')
  },

  mounted() {
    if (this.errorMessage) {
      this.showCheckTokenError = true;
    }
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        this.handleClose();
      }
    });
    const urlParams = new URLSearchParams(decodeURIComponent(window.location.search))
    const id = urlParams.get('id')
    const email = urlParams.get('email')

    // handle verify email
    if (id && email) {
      const params = {
        id: id,
        email: email
      }
      handleVerifyEmail(params)
    }

    //handle check valid suplier or not
    const uuidPrimaryData = this.$route?.query?.primary_uuid
    if(uuidPrimaryData) {
      // todo: call api check primary_uuid
      this.handleCheckPrimaryDataById(uuidPrimaryData)
    }
  },

  methods: {
    ...mapActions('supplier', ['setPrimaryDataForm']),

    clearDataTitleModel() {
      this.email = ''
      this.isActiveCloseIcon = false
    },
    submitForm() {
      if (!validateRequired(['email', 'password'])) {
        return;
      }

      loginApi({ email: this.email?.toLowerCase(), password: this.password.trim(), primary_uuid: this.$route?.query?.primary_uuid })
        .then((res) => {
          if (!res.user.status) {
            this.dialog = true;
          } else {
            if(res.user?.language?.code !== this.$i18n.locale) {
              localStorage.setItem('language', res.user?.language?.code)
              location.reload()
            }
            infoFormPrimaryDataApi(this.$route?.query?.primary_uuid).then((res) => { // check uuid after login
              const data = { ...res.data, primary_uuid: res.data?.uuid }
              this.setPrimaryDataForm(data)
              this.$router.push({ path: ROUTES.SUPPLIER, query: { primary_uuid: this.$route?.query?.primary_uuid} }); // uuid for register
            }).catch(() => {
              // redirect to home if role not suplier & 404 if role suplier
              const ROLE_SUPLIER = 6
              if(res.user.role_id === ROLE_SUPLIER) {
                this.$router.push('/404')
              } else {
                this.$router.push({ path: ROUTES.TYPE_SELECTION });
              }
            })
          }
        })
        .catch((err) => {
          if (err.code === 'block_account') {
            this.message = err.message;
          } else {
            this.message = getErrorMessge(err);
          }
          this.dialog = true;
        });
    },
    handleClose() {
      this.password = '';
      this.email = '';
      this.dialog = false;
    },
    handleCloseReset() {
      this.showCheckTokenError = false;
      this.$store.dispatch('userData/updateMessage', '');
    },

    handleCheckLockPass() {
      if (this.email && validate.regexMail.test(this.email)) {
        checkBlockAccApi({ email: this.email })
          .then(() => {
            this.$router.push({ path: '/auth/forgot-password' });
          })
          .catch((err) => {
            this.message = getErrorMessge(err);
            this.dialog = true;
          });
      } else {
        this.$router.push({ path: '/auth/forgot-password', query: this.$route.query });
      }
    },
    handleFocusEmail() {
      const emailError = document.getElementById('email_error')
      const emailImgError = document.getElementById('email_img')
      const email = document.getElementById('email')
      email.style.border = '2px solid #327998'
      emailError.style.display = 'none'
      emailImgError.style.display = 'none'
    },
    handleFocusPass() {
      const password_img = document.getElementById('password_img')
      const password_error = document.getElementById('password_error')
      const password = document.getElementById('password')
      const passwordEye = document.getElementById('password_eye')
      password.style.border = '2px solid #327998'
      password_img.style.display = 'none'
      password_error.style.display = 'none'
      passwordEye.style.display = 'block'
    },
    handleBlurEmail() {
      const email = document.getElementById('email')
      email.style.border = 'none'
    },
    handleBlurPass() {
      const password = document.getElementById('password')
      password.style.border = 'none'
    },
    async handleCheckPrimaryDataById(uuidPrimaryData) { //check uuid before login
      try {
        const responseDetail = await getInforPrimaryDataApiBeforeLogin(uuidPrimaryData)
        if(!responseDetail?.data){
          this.$router.push('/404')
        }
      } catch (error) {
        this.$router.push('/404')
      }
    }
  },
  components: {
    ErrorPopup,
    EyeIcon,
    ResetPassPopup,
    InputField
  },
  beforeDestroy() {
    document.removeEventListener('keyup', () => {});
  },
};
</script>

<style lang="scss" scoped>
@import '@/views/auth/LoginStyle/index.scss';

.while-color {
  background: #ffffff;
}
.none {
  display: none;
}

.block {
  display: block;
}
.forgot-block {
  margin-top: 16px;
}
.btn-submit {
  margin-top: 51px;
}
.wrap {
  min-height: 270px;
  overflow: auto;
}
.input-block{
  position: relative;
}
.clear-data-input-search {
  position: absolute;
  top: 44px;
  right: 11px;
  cursor: pointer;
}
</style>
