<template>
  <div>
    <v-dialog
      v-model="dialog"
      @click:outside="handleClickOutSite()"
      ref="dialog"
      max-width="464px"
    >
      <v-card>
        <v-card-title>
          <img src="@/assets/images/dialog/error.svg" />
          <span >{{$t('popup.title_error')}}</span>
        </v-card-title>
        <v-card-text>
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <common-button class="btn-displayed-one" type="colored" @action="submit" :label="confirmText || $t('list_menu.label_decision')"/>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import CommonButton from '@/components/commonApp/Button.vue';
import i18n from '@/lang/i18n';
export default {
  components: {CommonButton},
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    confirmText: {
      type: String,
      default: null,
    },
    message: {
      type: String,
      required: true,
    },
    classes: {
      type: String,
      required: false,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    submit() {
      this.$emit('submit');
    },
    handleClickOutSite() {
      this.$emit('submit');
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components/popup/index.scss';
</style>

